import {useEffect, useState} from "react";


export default function ProgressiveImageLoader({ imageBaseName, altText }) {
	const sizes = [128, 256, 512, 1024, 2048, 4096, 6000];
	const [currentSrc, setCurrentSrc] = useState(`/img/${imageBaseName}-128.JPG`);

	useEffect(() => {
		let isMounted = true;
		let currentIndex = 0;

		const loadNextImage = () => {
			if (currentIndex >= sizes.length) return;

			const nextSize = sizes[currentIndex];
			const nextSrc = `/img/${imageBaseName}-${nextSize}.JPG`;

			const img = new Image();
			img.src = nextSrc;

			img.onload = () => {
				if (isMounted) {
					setCurrentSrc(nextSrc);
					currentIndex++;
					loadNextImage(); // Load the next image in the sequence
				}
			};
		};

		// Start loading the first image
		loadNextImage();

		return () => {
			isMounted = false;
		};
	}, [imageBaseName]);

	return (
		<img
			src={currentSrc}
			alt={altText}
			className="h-[calc(100%-5rem)] object-contain"
		/>
	);
}