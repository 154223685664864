import {useLocation} from "react-router-dom";

import Button from "./Button";
import {useState} from "react";
import ProgressiveImageLoader from "./ProgressiveImageLoader";

const IMAGES = [
	['haus1.JPG', 'haus1.JPG'],
	['obelisk.JPG', 'obelisk.JPG'],
	['kirche.JPG', 'wasser3.JPG'],
	['bruecke5.JPG', 'flocke2.JPG'],
	['maxundmoritz.JPG', 'maxundmoritz.JPG'],
	['flocke2.JPG', 'bruecke5.JPG'],
	['wasser3.JPG', 'kirche.JPG'],
]

function App() {

	const {pathname} = useLocation()
	const CW = pathname.split("").filter(n => !isNaN(n)).join("") % 2 === 0

	const [index, setIndex] = useState(0)

	const decrement = () => {
		const tmp = index - 1
		setIndex(tmp < 0 ? IMAGES.length - 1 : tmp)
	}

	const increment = () => {
		const tmp = index + 1
		setIndex(tmp >= IMAGES.length ? 0 : tmp)
	}

	return (
		<main className={"h-dvh max-w-dvw p-4 bg-slate-700 text-white flex flex-col items-center"}>
			<ProgressiveImageLoader
				imageBaseName={IMAGES[index][CW ? 1 : 0].split('.')[0]}
				altText="Hundebild"
			/>

			<div className={"flex justify-between items-center mt-4 w-96"}>
				<Button variant={"primary"} onClick={decrement}>-</Button>

				<span>{index}</span>

				<Button variant={"primary"} onClick={increment}>+</Button>
			</div>
		</main>
	);
}

export default App;
